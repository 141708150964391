
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px*5));
  }
}

  .logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  
  /* .logos:hover .logos-slide {
    animation-play-state: paused;
  } */
  
  .logos-slide {
    display: inline-block;
    animation: scroll 20s linear infinite;
    width: calc(250px * 10);
  }


  .slide{
    width: 250px;
  }
  
  @media only screen and (max-width: 768px) {
    .logos:before,
    .logos:after {
      position: absolute;
      top: 0;
      width: 75px;
      height: 100%;
      content: "";
      z-index: 2;
    }
  }