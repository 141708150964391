@tailwind base;
@tailwind components;
@tailwind utilities;

*,*::after,*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth ;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}