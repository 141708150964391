:root {
    --main: #00058B;
    /* Violet */
    --black: #292B4A;
    --white: #FFFFFF;
    --secondary: #64748B;
    --gray: #EEEEF2;
    --background-linear: linear-gradient(173deg, #FFF 0%, #F0F0F0 100%);
    /*Pading*/
    --P1: 0px;
    --P2: 4px;
    --P3: 8px;
    --P4: 12px;
    --P5: 16px;
    --P6: 24px;
  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

// Full max width
.max-width {
    max-width: 1440px !important;
    overflow-x: hidden;
}

@media only screen and (max-width: 1439px) {
    .max-width {
        max-width: 100% !important;
    }
}

// main container 
.main-container {
    margin-top: 87px;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    .head-content {
        background: linear-gradient(107deg, rgba(24, 123, 245, 0.10) 0%, rgba(24, 123, 245, 0.00) 100%);
        height: 693px;
        width: 100%;
    }
}


body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--background-linear);
}


.z-999 {
    z-index: 999;
}




.navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    background: var(--white);
    transition: all 0.6s;
    padding: 16px 124px;
    height: 87px;
}

.handburger-menu-icon {
    display: none;
}


.ourStory .text {
    color: var(--secondary);
    text-align: justify;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.523%;
    /* 24.564px */
}

.ourStory .title {
    color: var(--black);
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 132.523%;
    /* 31.805px */
    text-align: center;

}

.wrapper::-webkit-scrollbar {
    width: 0;
}

.portItem-country {
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.get-quote-container {
    display: flex !important;
    flex-direction: row !important;
    gap: var(--P3) !important;
}


@media only screen and (min-width: 640px) {

    .services-list .item-title {
        color: var(--black);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140.5%;
        /* 33.72px */
        width: 311px;

    }

    .services-list .item-description {
        color: var(--Color, #64748B);
        text-align: center;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.5%;
        /* 22.48px */
        align-self: stretch;
    }

    .ourStory .text {
        color: var(--secondary);
        
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.523%;
        /* 36.845px */
        max-width: 1190px;
        text-align: left;
    }

    .ourStory .title {
        color: var(--black);
        
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 132.523%;
        /* 53.009px */
    }

}


.read-more-btn {
    color: #187BF5;
    
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 153.523%;
    text-decoration-line: underline;
    cursor: pointer;
}

.manager-text {
    color: var(--white);
    
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.023%;
    /* 21.603px */

}

.manager-name {
    color: var(--white);
    
    font-size: 18px;
    font-weight: 400;
    line-height: 144.023%;
}

@media only screen and (min-width: 640px) {
    .read-more-btn {
        color: #187BF5;
        
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        line-height: 153.523%;
        text-decoration-line: underline;
    }

    .manager-name {
        color: var(--white);
        
        font-size: 20px;
        font-weight: 400;
        line-height: 144.023%;
    }

    .manager-text {
        color: var(--white);
        
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 144.023%;
        /* 28.805px */
    }

}

@media only screen and (max-width: 991px) {
    .large-nav {
        display: none;
    }

    .handburger-menu-icon {
        display: block;
    }

    .nav {
        justify-content: space-between;
    }
}

.current-link-color {
    color: #187BF5;
}


.section-page-link {
    display: flex;
    padding: 10px;
    align-items: center;
}


.section-page-link,
.head-contact-btn,
.navbar-getFree-btn {
    color: var(--black);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.head-contact-btn,
.navbar-getFree-btn {
    transition: all 0.6s ease;
}

.head-contact-btn:hover,
.navbar-getFree-btn:hover {
    background: #187BF5;
}

.menu-underline {
    transition: all 0.4s;
    width: 0%;
    height: 2px;
}

.menu-item:hover .menu-underline {
    width: 100%;
    background: #187BF5;
}

.menu-item:hover{
    color : #187BF5;
}

.section-link {
    padding: var(--P1, 0px);
    align-items: center;
}

.navbar-getFree-btn {
    padding: 16px;
    color: var(--white);
    border-radius: 49px;
    background: var(--main);
    height: 52px;
    min-width: 172px;
}

.menuIsActive {
    transition: all 1s ease;
    transform: translateX(0);
    opacity: 1;
}

.menuIsNotActive {
transition: all 1s ease;
transform: translateX(-100%);
opacity: 1;
}

.apearIcon {
    transition: all 1.6s ease;
    transform: translateX(0);
}

.desapearIcon {
transition: all 2s ease;
transform: translateX(-200%);
}

.head-content {
    background: linear-gradient(107deg, rgba(24, 123, 245, 0.10) 0%, rgba(24, 123, 245, 0.00) 100%);
    /*padding: 0 125px;*/
}



.head-contact-btn {
    display: flex;
    /* padding: var(--P5, 16px) 16px; */
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--P6, 24px);
    background: var(--main);
    color: var(--white);

}

.head-title {
    color: var(--Black, #292B4A);
    
    line-height: 132.523%;
    width: 100%;

    font-size: 40px;
    font-weight: 600;
    line-height: 132.523%;
}

.head-title-highlight {
    color: var(--main);
}

.head-text {
    color: var(--secondary);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.523%;
    width: 100%;
}

.patner-list-section {
    background: var(--white);
}

.services-section {
    background: var(--gray);
}

.services-section .title,
.patner-list-section .title {
    color: var(--black, );
    
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.services-section .text-content,
.patner-list-section .text-content {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: var(--P5, 16px);
}

.services-section .text-content .text,
.patner-list-section .text-content .text {
    color: var(--Color, #64748B);
    text-align: center;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.523%;
    /* 36.845px */
}

.patner-list-section .patner-logo-list {
    display: flex;
    width: 1190px;
    align-items: flex-start;
    gap: 170px;
    margin-bottom: 100px;
}

.services-card {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    background: var(--white);
    border-radius: 16px;
    transition: all 0.8s ease-out;
}

.services-card:hover {
    overflow: visible;
    box-shadow: 1px 1px 27px -14px rgba(69, 69, 69, 0.67);
    -webkit-box-shadow: 1px 1px 27px -14px rgba(69, 69, 69, 0.67);
    -moz-box-shadow: 1px 1px 27px -14px rgba(69, 69, 69, 0.67);
    transition: all 0.8s ease-out;

}

.services-card :hover .card-title {
    transition: all 0.8s ease-out;
    color: var(--main);
}

.services-card :hover .card-description {
    transition: all 0.8s ease-out;
    color: var(--back);
}


.services-card .card-img {
    position: relative;
    width: 100%;
}

.services-card .card-img img {
    width: 100%;
    height: 241.555px;
    border-radius: var(--P5, 16px);

}

.services-card .card-title {
    color: var(--black);
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%;
}

.services-card .card-description {
    color: var(--secondary, #64748B);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.5%;
}

.OurServingPorts-section {
    background: url("../images/OurServingPortsBg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.managerProfil-section {
    background: url("../images/ManagerSectionBg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}


.OurServingPorts-section .OurServingPorts-title {
    color: var(--white);
    
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.OurServingPorts-section .OurServingPorts-description {
    width: 40rem;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.523%;
    /* 30.705px */
    color: var(--white);
}

.OurServingPorts .OurServingPorts-img {
    width: 411px;
    height: 411px;
    flex-shrink: 0;
}

.ourServices .title {
    color: var(--black);
    
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 132.523%;
    /* 53.009px */
    padding: 0;
    background: transparent;
}

.ourServices .text {
    color: var(--secondary);
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.523%;
    /* 24.564px */
}

.services-list .item-title {
    color: var(--black);
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%;
    /* 22.48px */
}

.services-list .item-description {
    color: var(--secondary);
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.5%;
    /* 22.48px */
    align-self: stretch;
}

.view-more-btn {
    color: #187BF5;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    padding: var(--P5, 16px) 16px;
    align-items: center;
    gap: 8px;
    border-radius: var(--P6, 24px);
}

.footer {
    background: url("../images/footerBg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 436px) {
    .footer {
        background: url("../images/Footer.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.footer .footer-container .title {
    color: var(--black);
    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.page-link * {
    width: 100%;
    white-space: nowrap;

}

.footer .contact-information {
    color: var(--black);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer .footer-container .text {
    position: relative;
    color: var(--black);
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    width: max-content;

    &::after {
        transition: all 0.4s;
        height: 2px;
        background-color: #187BF5;
        border-radius: 4px;
        width: 0px;
        position: absolute;
        top: 18px;
        left: 0px;
        content: '';
    }
    &:hover::after {
        width: 100%;
    }
}


.footer-contact-btn {
    display: flex;
    padding: var(--P5, 16px) 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--P6, 24px);
    background: var(--main);
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    transition: all 0.8s ease-out;
}

.footer-contact-btn:hover {
    background-color: #187BF5;
}


.handburger-menu-link * {
    font-feature-settings: 'clig' off, 'liga' off;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
}


@media only screen and (max-width: 769px) {
    
    .file-input-box-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100% !important;
        height: 120px;
        justify-content: center;
        gap: 10px;
        border-radius: var(--P5, 16px);
        border: 1px dashed rgba(100, 116, 139, 0.50);
        background: rgba(24, 123, 245, 0.04);
    }

    .navbar {
        padding: var(--P6) 64px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .head-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 132.523%;
    }

    .head-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.523%;
    }

    .services-section .title,
    .patner-list-section .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .services-section .text-content .text,
    .patner-list-section .text-content .text {
        width: 80vw;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.523%;
        /* 24.564px */
    }

    .OurServingPorts-section .OurServingPorts-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }

    .OurServingPorts-section .OurServingPorts-description {
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.523%;
        /* 30.705px */
        width: 88vw;
    }

    .services-card .card-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140.5%;
        /* 28.1px */
        white-space: wrap;
    }

    .services-card .card-description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.5%;
    }

    .service-title {
        color: var(--white);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: fit-content;
        align-self: center;
        padding: var(--P3) 32px;
        background: var(--main);
    }

    .ourServices .text {
        color: var(--secondary);
        text-align: center;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.523%;
        /* 24.564px */
    }

}

.about-card-btn-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}


.about-card-btn {
    display: flex;
    cursor: pointer;
    height: 52px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    color: var(--secondary);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.1px;

}
.about-card-btn:hover {
    display: flex;
    padding: var(--P3, 8px) var(--left-right-meduim, 24px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #EDEDED;
}

.about-card-btn-active {
    display: flex;
    height: 52px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #187BF5;
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.1px;

}

.about-card .title {
    color: var(--black);
    
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 132.523%;
    /* 53.009px */
}

.about-card .description {
    color: var(--secondary);
    
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.523%;
    /* 30.705px */
}



@media only screen and (max-width: 640px) {
    

    .about-card .title {
        color: var(--back);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 132.523%;
        /* 31.805px */
    }

    .about-card .description {
        color: var(--Color, #64748B);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.523%;
        /* 30.705px */
    }

    }

    @media only screen and (max-width: 400px) {

    .services-card {
        position: relative;
        display: flex;
        width: 300px;
        padding: 16px 12px 32px 12px;
        align-items: flex-start;
        gap: 10px;
        background: var(--white);
        border-radius: var(--P5, 16px);
    }

    /* .services-card:hover{
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.10);
    } */

    .services-card .card-img img {
        position: relative;
        width: 100%;
        height: max-content;
        border-radius: var(--P4, 12px);
    }


    .services-card .card-description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.5%;
    }

}

.contact-form-container .title {
    color: var(--black);
    
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 132.523%;
    /* 53.009px */
}

.contact-form-container .description {
    color: var(--Color, #64748B);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.523%;
    /* 21.204px */
    width: 100%;
}

.form div {
    display: flex;
    flex-direction: column;
    gap: var(--P3);
}

.form input,
.form textarea {
    padding: var(--P4) var(--P5);
    border-radius: var(--P5);
    border: 1px solid rgba(100, 116, 139, 0.50);

}

.form textarea::-webkit-scrollbar {
    width: 0;
}

.form textarea {
    height: 120px;
}

.form div label {
    color: var(--black);
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.523%;
    /* 23.854px */
}

.form div input::placeholder,
.form div textarea::placeholder {
    color: var(--secondary);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.file-input-box {
    display: flex;
    height: 120px;
    padding: var(--P5, 16px) var(--P4, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--P5, 16px);
    border: 1px dashed rgba(100, 116, 139, 0.50);
    background: rgba(24, 123, 245, 0.04);
}

.file-input-box-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 120px;
    justify-content: center;
    gap: 10px;
    border-radius: var(--P5, 16px);
    border: 1px dashed rgba(100, 116, 139, 0.50);
    background: rgba(24, 123, 245, 0.04);
}


.file-list {
    width: 600px;
    max-height: 488px;
    flex-shrink: 0;
    overflow-y: scroll;
    overflow-x: hidden;

}


.file-list::-webkit-scrollbar {
    width: 8px;
}

.file-list::-webkit-scrollbar-thumb {
    border-radius: var(--P3, 8px);
    background: #D9D9D9;
}

.file-item-box {
    display: flex;
    padding: var(--P5, 16px) var(--P6, 24px) 20px var(--P5, 16px);
    justify-content: space-between;
    align-items: center;
    border-radius: var(--P5, 16px);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
}

.submit-conctat-message {
    display: flex;
    width: 100%;
    padding: var(--P5, 16px) 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    background: var(--main);
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    transition: all 0.6s ease;
}

.submit-conctat-message:hover,
.submit-conctat-message-modal:hover {
    background-color: #187BF5;
}

.submit-conctat-message-modal {
    padding: var(--P5, 16px) 16px;
    gap: 8px;
    border-radius: 32px;
    background: var(--main);
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    transition: all 0.6s ease;
}

.message-span {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    transition: all 0.6s ease;
}

.error-message {
    color: #ba0c26;
}

.success-message {
    color: #0c9d33;
}

.submit-conctat-message:disabled {
    opacity: .4;
}
.qote-modal {
    height: 100%;
    max-height: calc(100vh - 2rem) !important;
}

@media only screen and (min-width: 1441px) {
    .qote-modal {
        max-height: 722px !important;
    }
}

@media only screen and (max-width: 960px) {
    .qote-modal {
        max-height: 100vh !important;
    }
}
@media only screen and (max-width: 768px) {
    .qote-modal {
        max-height: 100% !important;
    }
}
.getFreeQuote-modal {
    background: url("../images/modalGetFreeQuote.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    max-height: 533px;
}

.getFreeQuote-modal .form {
    box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.25);
}

.getFreeQuote-modal::-webkit-scrollbar {
    display: none;
}

.getFreeQuote-modal .title {
    color: var(--White, #FFF);
    
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modalIsClose {
    display: none;
}


@media only screen and (max-width: 1118px) {
    .contact-form-container .title {
        color: var(--black);
        
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 132.523%;
        /* 42.407px */
    }


    .contact-form-container .description {
        color: var(--secondary);
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 132.523%;
        /* 21.204px */
        width: 100%;
    }

    .form div {
        display: flex;
        flex-direction: column;
        gap: var(--P3);
    }

    .form input,
    .form textarea {
        padding: var(--P4) var(--P5);
        border-radius: var(--P5);
        border: 1px solid rgba(100, 116, 139, 0.50);

    }

    .form textarea::-webkit-scrollbar {
        width: 0;
    }

    .form textarea {
        height: 120px;
    }

    .form div label {
        color: var(--black);
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 132.523%;
        /* 23.854px */
    }

    .form div input::placeholder,
    .form div textarea::placeholder {
        color: var(--secondary);
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .file-input-box {
        display: flex;
        width: 100%;
        height: 120px;
        padding: var(--P5, 16px) var(--P4, 12px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: var(--P5, 16px);
        border: 1px dashed rgba(100, 116, 139, 0.50);
        background: rgba(24, 123, 245, 0.04);
    }


    .file-list {
        max-height: 488px;
        width: 90vw;
        flex-shrink: 0;
        overflow-y: scroll;
        overflow-x: hidden;
    }

}

.file-name {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.browse-file-btn {
    color: #187BF5;
    text-align: center;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.select-file-text {
    color: var(--secondary);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.5px;
    /* 139.286% */
}

@media only screen and (max-width: 1298px) {
    .navbar {
        padding: 16px 20px;
    }
}

@media only screen and (max-width: 669px) {
    .scoll-menu {
        display: flex;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: hidden;
    }
}


@media only screen and (max-width: 768px) {
    .footer .title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .getFreeQuote-modal .form {
        box-shadow: none !important;
    }
    .file-item-box {
        padding: 0px !important;
        border-radius: 0px;
        box-shadow: none !important;
        flex-direction: row !important;
    }

    .document-file {
        flex-direction: row !important;

        svg {
            width: 24px !important;
        }
    }

    .delete-document {
        svg {
            width: 20px !important;
        }
    }
}

